<template>
	<div>
		<b-modal ref="modal" hide-footer>
			<template v-slot:modal-title>
				<template>
					{{ $t("global.my_details") }}
				</template>
			</template>

			<div v-if="!modal_loaded">
				<LoadingSpinner class="col-12" />
			</div>

			<div v-if="modal_loaded">
				<form @submit.prevent="checkForm" id="addTiers"  autocomplete="off">
					<div class="row">
						<div class="col-12" v-if="form_message !== ''">
							<ErrorAlert :messageI18n="form_message" />
						</div>

						<div class="col-12">
							<WarnAlert v-if="tier_already_exists" messageI18n="tiers.already_exist" />
						</div>

						<div class="col-12">
							<div class="form-group">
								<label for="tiers_civility" class="col-form-label">{{ $t("tiers.civility") }} *</label>
								<e-select
									id="tiers_civility"
									v-model="form.tiers_civility"
									:options="civilities"
									:allow-empty="false"
									:show-labels="false"
									:disabled="readonly.indexOf('civility') > -1"
								/>
							</div>
							<div class="row">
								<div class="col-6">
									<div class="form-group">
										<label for="tiers_firstname" class="col-form-label">{{ $t("tiers.firstname") }} *</label>
										<input type="text" id="tiers_firstname" class="form-control" v-model="form.tiers_firstname" required  autocomplete="chrome-off" :class="{ 'is-invalid': error && error.indexOf('firstname') > -1 }" :disabled="readonly.indexOf('firstname') > -1">
									</div>
								</div>
								<div class="col-6">
									<div class="form-group">
										<label for="tiers_lastname" class="col-form-label">{{ $t("tiers.lastname") }} *</label>
										<input type="text" id="tiers_lastname" class="form-control" v-model="form.tiers_lastname" required  autocomplete="chrome-off" :class="{ 'is-invalid': error && error.indexOf('lastname') > -1 }" :disabled="readonly.indexOf('lastname') > -1">
									</div>
								</div>
							</div>
						</div>
					</div>
					
					<div class="row">
						<div class="col-12">
							<div class="form-group">
								<label for="tiers_address1" class="col-form-label">{{ $t("tiers.address1") }} *</label>
								<input type="text" id="tiers_address1" class="form-control" v-model="form.tiers_address1" required  autocomplete="chrome-off" :class="{ 'is-invalid': error && error.indexOf('address1') > -1 }" :disabled="readonly.indexOf('address1') > -1">
							</div>
						</div>
						<div class="col-12">
							<div class="form-group">
								<label for="tiers_address2" class="col-form-label">{{ $t("tiers.address2") }}</label>
								<input type="text" id="tiers_address2" class="form-control" v-model="form.tiers_address2" autocomplete="chrome-off" :class="{ 'is-invalid': error && error.indexOf('address2') > -1 }" :disabled="readonly.indexOf('address2') > -1">
							</div>
						</div>
						<div class="col-4">
							<div class="form-group">
								<label for="tiers_postalcode" class="col-form-label">{{ $t("tiers.postalcode") }} *</label>
								<input type="text" id="tiers_postalcode" class="form-control" v-model="form.tiers_postalcode" required  autocomplete="chrome-off" :class="{ 'is-invalid': error && error.indexOf('postalcode') > -1 }" :disabled="readonly.indexOf('postalcode') > -1">
							</div>
						</div>
						<div class="col-8">
							<div class="form-group">
								<label for="tiers_town" class="col-form-label">{{ $t("tiers.town") }} *</label>
								<input type="text" id="tiers_town" class="form-control" v-model="form.tiers_town" required autocomplete="chrome-off" :class="{ 'is-invalid': error && error.indexOf('town') > -1 }" :disabled="readonly.indexOf('town') > -1">
							</div>
						</div>
						<div class="col-12">
							<div class="form-group">
								<label for="tiers_country" class="col-form-label">{{ $t("tiers.country") }} *</label>
								<CountryInput v-model="form.tiers_country" :disabled="readonly.indexOf('country') > -1"></CountryInput>
							</div>
						</div>
						<div class="col-12">
							<div class="form-group">
								<label for="tiers_vat_number" class="col-form-label">{{ $t("tiers.tiers_vat_number") }}</label>
								<input type="text" id="tiers_vat_number" class="form-control" v-model="form.tiers_vat_number" autocomplete="chrome-off" :disabled="readonly.indexOf('vat_number') > -1">
							</div>
						</div>
						<div class="col-12">
							<div class="form-group">
								<label for="phone_number" class="col-form-label">{{ $t("tiers.phone_number") }} *</label>
								<div class="row no-gutters phone">
									<div v-if="readonly.indexOf('phone_number') == -1" class="col-auto pr-2">
										<phoneInput v-model="phone"></phoneInput>
									</div>
									<div class="col pr-2">
										<div class="input-group">
											<div class="input-group-prepend">
												<b-form-input type="text" class="form-control" style="width: 5em;" v-model="phone.phone_indicatif" :disabled="readonly.indexOf('phone_number') > -1" :class="{ 'is-invalid': error && error.indexOf('phone') > -1 }"/>
											</div>
											<input type="text" class="form-control" v-model="phone.phone_numero" required  autocomplete="chrome-off" :disabled="readonly.indexOf('phone_number') > -1" :class="{ 'is-invalid': error && error.indexOf('phone') > -1 }">
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</form>

				<div class="text-center">
					<b-button variant="primary" @click.prevent="checkForm" :disabled="error.length > 0"><font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse /> {{ $t("global.valider") }} <font-awesome-icon :icon="['fal', 'check']"/></b-button>
				</div>
			</div>
		</b-modal>
	</div>
</template>

<script type="text/javascript">
	import Vue from 'vue'
	import Tiers from '@/mixins/Tiers.js'
	import _cloneDeep from 'lodash/cloneDeep';
	import _isEmpty from 'lodash/isEmpty'
	import Indicatif from 'GroomyRoot/assets/lang/countries/indicatif'
    import Countries from '@/assets/lang/countries/countries'

	export default {
		name: "InformationsTiersForm",
		mixins: [Tiers],
		data () {
			return {
				modal_loaded: false,
				processing: false,
                form_message: "",
                tier_already_exists: false,
				form: {},
				countries: {},
				country_choosen: false,
				civilities: [
					this.getTrad('civility.m'),
					this.getTrad('civility.mme')
				],
				default_form: {
					tiers_rs: '',
					tiers_firstname: '',
					tiers_lastname: '',
					tiers_civility: this.getTrad('civility.m'),
					tiers_address1: '',
					tiers_address2: '',
					tiers_postalcode: '',
					tiers_town: '',
					tiers_country: '75',
					tiers_vat_number: ''
				},
				error: [],
				readonly: [],
				errors: [],
				phone: {
					phone_indicatif: '+33',
					phone_number: '',
					phone_country: 'FRA'
				}
			}
		},
		mounted() {
			this.init_component()
		},
		methods: {
			async init_component() {
			},
			openModal(tiers) {
				if(!_isEmpty(tiers.tiers_civility) && !_isEmpty(tiers.tiers_lastname) && !_isEmpty(tiers.tiers_firstname) && !_isEmpty(tiers.tiers_country.toString())
				&& !_isEmpty(tiers.tiers_address1) && !_isEmpty(tiers.tiers_postalcode) && !_isEmpty(tiers.tiers_town) && !_isEmpty(tiers.phone_number)) {
					this.$emit('submit', true)
				}
				else {
					this.$refs['modal'].show()
					this.modal_loaded = true
					this.form = _cloneDeep(tiers)
					this.form.tiers_civility = this.default_form.tiers_civility

					if(tiers.phones.length > 0) {
						this.phone.phone_indicatif = tiers.phones[0].phone_indicatif
						this.phone.phone_numero = tiers.phones[0].phone_numero
					}

					if(tiers.tiers_civility != null) {
						if(this.civilities.indexOf(this.getTrad('civility.' + this.form.tiers_civility)) > -1){
							this.form.tiers_civility = this.getTrad('civility.' + this.form.tiers_civility)
						}
					}

					this.readonly = []
					if(!_isEmpty(tiers.tiers_civility)) {
						this.readonly.push('civility')
					}
					if(!_isEmpty(tiers.tiers_lastname)) {
						this.readonly.push('lastname')
					}
					if(!_isEmpty(tiers.tiers_firstname)) {
						this.readonly.push('firstname')
					}
					if(!_isEmpty(tiers.tiers_country.toString())) {
						this.readonly.push('country')
					}
					if(!_isEmpty(tiers.tiers_address1)) {
						this.readonly.push('address1')
					}
					if(!_isEmpty(tiers.tiers_address2)) {
						this.readonly.push('address2')
					}
					if(!_isEmpty(tiers.tiers_postalcode)) {
						this.readonly.push('postalcode')
					}
					if(!_isEmpty(tiers.tiers_town)) {
						this.readonly.push('town')
					}
					if(!_isEmpty(tiers.tiers_vat_number)) {
						this.readonly.push('vat_number')
					}
					if(tiers.phones.length > 0) {
						this.readonly.push('phone_number')
					}
				}
			},
			checkForm: async function () {
				if(!this.processing)
				{
					this.processing = true

					this.form_message = ""
					this.error = []

					// if(_isEmpty(this.form.tiers_firstname) || /^\s+$/.test(this.form.tiers_firstname)){
					// 	this.error.push("firstname") 
					// }
					// if(_isEmpty(this.form.tiers_lastname) || /^\s+$/.test(this.form.tiers_lastname)){
					// 	this.error.push("lastname") 
					// }
					// if(_isEmpty(this.form.tiers_civility) || /^\s+$/.test(this.form.tiers_civility)){
					// 	this.error.push("civility") 
					// }

					if(_isEmpty(this.form.tiers_firstname)){
						this.error.push("firstname") 
					}
					if(_isEmpty(this.form.tiers_lastname)){
						this.error.push("lastname") 
					}
					if(_isEmpty(this.form.tiers_civility)){
						this.error.push("civility") 
					}

					if(!this.form.tiers_country) {
						this.error.push("country")
					}

					// if(_isEmpty(this.form.tiers_address1) || /^\s+$/.test(this.form.tiers_address1)) {
					// 	this.error.push("address1")
					// }

					// if(_isEmpty(this.form.tiers_postalcode) || /^\s+$/.test(this.form.tiers_postalcode)) {
					// 	this.error.push("postalcode")
					// }

					// if(_isEmpty(this.form.tiers_town) || /^\s+$/.test(this.form.tiers_town)) {
					// 	this.error.push("town")
					// }

					if(_isEmpty(this.form.tiers_address1)) {
						this.error.push("address1")
					}

					if(_isEmpty(this.form.tiers_postalcode)) {
						this.error.push("postalcode")
					}

					if(_isEmpty(this.form.tiers_town)) {
						this.error.push("town")
					}

					if(_isEmpty(this.phone.phone_numero)) {
						this.error.push("phone")
					}

					if(this.error.length == 0) {
						if(this.form.tiers_civility == this.getTrad('civility.m')) {
							this.form.tiers_civility = 'm'
						}
						else if(this.form.tiers_civility == this.getTrad('civility.mme')) {
							this.form.tiers_civility = 'mme'
						}
	                    const params = _cloneDeep(this.form)
	                    params.phone = this.phone
						let result = await this.editTiers(params)

						if(!result) {
							this.failureToast("toast.info_save_failed")
							this.processing = false
							this.$emit('submit', false)
						}
						else {
							this.$emit('submit', true)
						}

						this.$refs['modal'].hide()
					} else {
						this.processing = false
						this.form_message = "formulaire.erreur_champs_non_remplis"
					}
				}
            },

            checkSpecialChar() {
            	this.error = []
				let format = /^[A-Z- ]*$/;

				if(!format.test(this.form.tiers_firstname)) {
					this.error.push("firstname") 
				}
				if(!format.test(this.form.tiers_lastname)) {
					this.error.push("lastname")
				}

				if(!format.test(this.form.tiers_town)) {
					this.error.push("town")
				}

				format = /^[0-9 ]*$/;
				if(!format.test(this.form.tiers_postalcode)) {
					this.error.push("postalcode")
				}

				format = /^[A-Za-z0-9 ]*$/;
				if(!format.test(this.form.tiers_address1)) {
					this.error.push("address1")
				}

				if(!format.test(this.form.tiers_address2)) {
					this.error.push("address2")
				}
            }
        },
		watch: {
			'form.tiers_firstname' (val) {
				if(val) {
					this.form.tiers_firstname = val.toUpperCase()
					// this.checkSpecialChar()
				}
			},
			'form.tiers_lastname' (val) {
				if(val) {
					this.form.tiers_lastname = val.toUpperCase()
					// this.checkSpecialChar()
				}
			},
			// 'form.tiers_postalcode' (val) {
			// 	this.checkSpecialChar()
			// },
			'form.tiers_town' (val) {
				if(val) {
					this.form.tiers_town = val.toUpperCase()
					// this.checkSpecialChar()
				}
			},
			'form.tiers_address1' (val) {
				if(val) {
					this.form.tiers_address1 = val.toUpperCase()
					// this.checkSpecialChar()
				}
			},
			'form.tiers_address2' (val) {
				if(val) {
					this.form.tiers_address2 = val.toUpperCase()
					// this.checkSpecialChar()
				}
			},
			'phone.phone_country' (val) {
				if(val) {
					this.phone.phone_indicatif = Indicatif[val]
				}
			}
		},
		components: {
			LoadingSpinner : () => import('GroomyRoot/components/Logos/LoadingSpinner_35'),
			ErrorAlert : () => import('GroomyRoot/components/Alert/ErrorAlert'),
            CountryInput : () => import('GroomyRoot/components/Inputs/CountryInput'),
            WarnAlert : () => import('GroomyRoot/components/Alert/WarnAlert'),
			PhoneInput : () => import('GroomyRoot/components/Inputs/PhoneInput')
		}
	}
</script>